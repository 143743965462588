import FlightIcon from "@mui/icons-material/Flight";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TrainIcon from "@mui/icons-material/Train";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import CharacterIcon from "./components/CharacterIcon";
import mickey from "./assets/be_cool_disney/Mickey.png";
import minnie from "./assets/be_cool_disney/Minnie.png";
import donald from "./assets/be_cool_disney/Donald.png";
import mulan from "./assets/be_cool_disney/Mulan.png";
import olaf from "./assets/be_cool_disney/Olaf.png";
import simba from "./assets/be_cool_disney/Simba.png";
import stitch from "./assets/be_cool_disney/Stitch.png";
import tinker_bell from "./assets/be_cool_disney/Tinker Bell.png";

export const TRAVEL_OPTIONS = [
  { id: "t1", name: "Plane", icon: <FlightIcon /> },
  { id: "t2", name: "Car", icon: <DirectionsCarIcon /> },
  { id: "t3", name: "Train", icon: <TrainIcon /> },
  { id: "t4", name: "Bus", icon: <DirectionsBusIcon /> },
];

export const CONTINENT_OPTIONS = [
  { id: "c1", name: "Asia" },
  { id: "c2", name: "Africa" },
  { id: "c3", name: "Europe" },
  { id: "c4", name: "North America" },
  { id: "c5", name: "South America" },
  { id: "c6", name: "Oceania" },
];

export const PREVIOUS_ATTENDEE_OPTIONS = [
  { id: "a1", name: "Yes", icon: <CheckIcon /> },
  { id: "a2", name: "No", icon: <DoNotDisturbIcon /> },
];

export const OCCUPATION_OPTIONS = [
  { id: "o1", name: "Laboratory Technician" },
  { id: "o4", name: "Pathologist" },
  { id: "o5", name: "Technical Director" },
  { id: "o6", name: "General Supervisor" },
  { id: "o8", name: "Laboratory Director" },
  { id: "o9", name: "Sales/Marketing" },
  { id: "o11", name: "Researcher" },
  { id: "o12", name: "Other" },
];

export const MM_OPTIONS = [
  { id: "m1", name: "Purple" },
  { id: "m2", name: "Blue" },
  { id: "m3", name: "Green" },
];

export const DISNEY_CHARACTER_OPTIONS = [
  {
    id: "d1",
    name: "Mickey",
    portrait: <CharacterIcon image={mickey} name="Mickey Mouse" />,
  },
  {
    id: "d2",
    name: "Minnie",
    portrait: <CharacterIcon image={minnie} name="Minnie Mouse" />,
  },
  {
    id: "d3",
    name: "Donald",
    portrait: <CharacterIcon image={donald} name="Donald Duck" />,
  },
  {
    id: "d4",
    name: "Simba",
    portrait: <CharacterIcon image={simba} name="Simba" />,
  },
  {
    id: "d5",
    name: "Olaf",
    portrait: <CharacterIcon image={olaf} name="Olaf" />,
  },
  {
    id: "d6",
    name: "Stitch",
    portrait: <CharacterIcon image={stitch} name="Stitch" />,
  },
  {
    id: "d7",
    name: "Tinker Bell",
    portrait: (
      <CharacterIcon image={tinker_bell} name="Tinker Bell" />
    ),
  },
  {
    id: "d8",
    name: "Mulan",
    portrait: <CharacterIcon image={mulan} name="Mulan" />,
  },
];
