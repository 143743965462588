import styled from "styled-components";

const Slide = styled.div`
  width: 800px;
  height: 100%;
  color: var(--phy-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;

  & h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 3rem;
    font-weight: 500;
    margin-bottom: 32px;
  }

  & p {
    font-size: 1.25rem;
    letter-spacing: 1px;
    font-weight: 300;
    width: 75%;
    text-align: center;
    line-height: 2rem;
    margin-bottom: 32px;
  }

  & #optionButtons {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  & form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & form button {
    margin-top: 16px;
    width: 429px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    width: 90%;
    min-height: 50vh;
    justify-content: space-around;
    & h1 {
      font-size: 1.55rem;
      margin-bottom: 16px;
    }
    & p {
      font-size: 1rem;
      letter-spacing: normal;
      line-height: 1.23rem;
      margin-bottom: 16px;
    }
    & form button {
      width: 90%;
    }
    & #optionButtons {
      width: 95%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 400px) {
    & #optionButtons {
      width: 95%;
      height: 100%;
      display: flex;
      /* flex-wrap: wrap; */
      justify-content: space-around;
    }
  }
`;

export default Slide;
