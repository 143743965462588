import styled from "styled-components";

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.inner ? "50%;" : "128px"};
  height: ${props => props.inner ? "50%;" : "128px"};
  border-top: 16px solid var(--color-phyGreenHue);
  border-left: 16px solid var(--color-phyGreenHue);
  border-bottom: 16px solid transparent;
  border-right: 16px solid transparent;
  border-radius: 100%;
  padding: 8px;
  opacity: 0;
  animation: ${props => props.inner ? "fadeIn 0.5s ease-in-out forwards, spin 0.75s linear reverse infinite;" : "fadeIn 0.5s ease-in-out forwards, spin 1.5s linear infinite;"};
  /* animation: spin 1s ease-in-out infinite; */

  @keyframes spin {
    100% {
      /* transform: ${props => props.inner ? "rotate(-360deg)" : "rotate(360deg)"}; */
      transform: rotate(360deg);
    }
  }
  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }
`;

export default Loader;