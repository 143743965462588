import styled from "styled-components";

const OptionButton = styled.button`
  width: ${(props) => (props.iconless ? "288px" : "128px")};
  height: ${(props) => (props.iconless ? "64px" : "128px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-style: none;
  border: 3px solid var(--phy-white);
  border-radius: 15px;
  background: ${(props) =>
    props.color ? `var(--phy-${props.color});` : "transparent"};
  background-image: ${(props) =>
    props.gradient ? `linear-gradient(${props.gradient});` : null};
  color: var(--phy-white);
  margin: ${(props) => (props.iconless ? "8px;" : null)};
  box-shadow: ${(props) => (props.shadow ? `${props.shadow};` : null)};

  & svg {
    font-size: 60px;
  }
  & h3 {
    font-size: ${(props) => (props.iconless ? "1rem;" : "1.5rem;")};
    padding: ${(props) => (props.iconless ? "16px 0;" : null)};
    font-weight: ${(props) => (props.iconless ? "bold;" : null)};
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    transition: 0.1s;
    transform: scale(1.05, 1.05);
  }
  &:active {
    transform: scale(0.95, 0.95);
  }

  @media screen and (max-width: 800px) {
    width: ${(props) => (props.iconless ? "144px" : "86px")};
    height: ${(props) => (props.iconless ? "64px" : "86px")};
    margin: ${(props) => (props.iconless ? null : "8px")}
    & svg {
      font-size: 30px;
    }

    & h3 {
      font-size: ${(props) => (props.iconless ? "1rem;" : "1rem;")};
      padding: ${(props) => (props.iconless ? "16px 0;" : null)};
      font-weight: ${(props) => (props.iconless ? "bold;" : null)};
      line-height: 1.25rem;
    }
  }
  @media screen and (max-width: 400px) {
    width: ${(props) => (props.iconless ? "120px" : "86px")};
    height: ${(props) => (props.iconless ? "64px" : "86px")};
    margin: ${(props) => (props.iconless ? null : "8px")}
    & svg {
      font-size: 30px;
    }

    & h3 {
      font-size: ${(props) => (props.iconless ? ".8rem;" : "1rem;")};
      padding: ${(props) => (props.iconless ? "16px 0;" : null)};
      font-weight: ${(props) => (props.iconless ? "bold;" : null)};
      line-height: 1.25rem;
    }
  }
`;

export default OptionButton;
