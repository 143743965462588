import OptionButton from "./styles/OptionButton.styled";
import CharacterOption from "./styles/CharacterOption.styled";
import Slide from "./styles/Slide.styled";
import Button from "./styles/Button.styled";

const ContentSlide = (props) => {
  let buttonSection;
  if (props.options && props.options.length) {
    if (props.portraits) {
      buttonSection = (
        <div id="optionButtons">
          {props.options.map((t) => {
            return (
              <CharacterOption
                value={t.name}
                onClick={props.onClick}
              >
                {t.portrait}
                <h3>{t.name}</h3>
              </CharacterOption>
            );
          })}
        </div>
      );
    } else {
      buttonSection = (
        <div id="optionButtons">
          {props.options.map((t) => {
            return (
              <OptionButton
                iconless={props.iconless}
                value={t.name}
                key={t.id}
                onClick={props.onClick}
                color={t.color}
                gradient={t.gradient}
              >
                {t.icon}
                <h3>{t.name}</h3>
              </OptionButton>
            );
          })}
        </div>
      );
    }
  } else {
    buttonSection = <Button onClick={props.onClick}>Let's do it!</Button>;
  }

  return (
    <Slide>
      <h1>{props.heading}</h1>
      {props.subHeading && <p>{props.subHeading}</p>}
      {buttonSection}
    </Slide>
  );
};

export default ContentSlide;
