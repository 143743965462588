import { useState } from "react";
import Slide from "./styles/Slide.styled";
import Button from "./styles/Button.styled";
import EmailInput from "./EmailInput";
import Loader from "./styles/Loader.styled";

const FormSubmission = (props) => {
  const [inputTouched, setInputTouched] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [error, setError] = useState(undefined);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkEmail = (e) => {
    setInputTouched(true);
    if (enteredEmail.trim().length === 0) {
      console.log("EMAIL INPUT WAS TOUCHED BUT NOTHING WAS ENTERED.");
    }
  };

  const emailEnteredHandler = (e) => {
    setError("");
    setEnteredEmail(e.target.value);
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(re.test(e.target.value));
    if (re.test(e.target.value)) {
      setEmailValid(true);
    } else if (emailValid) {
      setEmailValid(false);
    }
  };

  const validateEmailInput = (e) => {
    e.preventDefault();
    if (!inputTouched) {
      setEmailValid(false);
      // setError("Please enter a valid email address");
    }
    setIsSubmitting(true);
    const emailAddress = enteredEmail.trim();
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(emailAddress);
    if (re.test(emailAddress)) {
      // console.log("VALID");
      // setInputTouched(false);
      // setEmailValid(true);
      props.submitHandler(emailAddress);
      setEnteredEmail("");
    } else {
      console.log("NOT VALID");
      setError("Please enter a valid email address");
      setEmailValid(false);
    }
  };

  return (
    <>
      <Slide>
        <h1>{props.heading}</h1>
        {props.subHeading && <p>{props.subHeading}</p>}
        {isSubmitting && (
          <Loader>
            <Loader inner={true} />
          </Loader>
        )}
        {!isSubmitting && (
          <form onSubmit={validateEmailInput} onBlur={checkEmail}>
            <EmailInput
              type="email"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailEnteredHandler}
            />
            <Button disabled={!emailValid || error}>Submit My Answers</Button>
          </form>
        )}
        {error && <p>{error}</p>}
      </Slide>
    </>
  );
};

export default FormSubmission;
