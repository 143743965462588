import Input from "./styles/Input.styled";
import EmailIcon from "@mui/icons-material/Email";

const EmailInput = (props) => {
  return (
    <Input>
      <EmailIcon />
      <input
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </Input>
  );
};

export default EmailInput;
