import { useState, useEffect } from "react";
// import GlobalStyles from "./components/GlobalStyles";
import Container from "./components/styles/Container.styled";
import ExitButton from "./components/ExitButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  TRAVEL_OPTIONS,
  CONTINENT_OPTIONS,
  PREVIOUS_ATTENDEE_OPTIONS,
  OCCUPATION_OPTIONS,
  MM_OPTIONS,
  DISNEY_CHARACTER_OPTIONS,
} from "./dataOptions";
import ContentSlide from "./components/ContentSlide";
import FormSubmission from "./components/FormSubmission";
import SlideCounter from "./components/SlideCounter";
import CompletionScreen from "./components/CompletionScreen";
import { getWindowDimensions } from "./helpers";
import db from "./firebase-config";
import "./globalStyle.css";
import firebase from "firebase/compat/app";
import { Routes, Route } from "react-router-dom";
import logo from "./assets/phyLogo-white.png";

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [continent, setContinent] = useState(undefined);
  const [disneyCharacter, setDisneyCharacter] = useState(undefined);
  const [previousAttendee, setPreviousAttendee] = useState(false);
  const [returningVisitor, setReturningVisitor] = useState(false);
  const [occupation, setOccupation] = useState(undefined);
  const [mmColor, setMmColor] = useState(undefined);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // effect for getting window dimensions and handling resizing
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const beginSurveyHandler = (e) => {
    e.preventDefault();
    setCurrentSlide(1);
  };

  const continentHandler = (e) => {
    e.preventDefault();
    setContinent(e.currentTarget.value);
    setCurrentSlide(2);
  };

  const disneyCharacterHandler = (e) => {
    e.preventDefault();
    setDisneyCharacter(e.currentTarget.value);
    setCurrentSlide(6);
  };

  const previousAttendeeHandler = (e) => {
    e.preventDefault();
    if (e.currentTarget.value === "Yes") {
      setPreviousAttendee(true);
    } else {
      setPreviousAttendee(false);
      setCurrentSlide(3);
    }
  };

  const returningVisitorHandler = (e) => {
    e.preventDefault();
    if (e.currentTarget.value === "Yes") setReturningVisitor(true);
    else setReturningVisitor(false);
    setCurrentSlide(3);
  };

  const occupationHandler = (e) => {
    e.preventDefault();
    setOccupation(e.currentTarget.value);
    setCurrentSlide(4);
  };

  const mmColorHandler = (e) => {
    e.preventDefault();
    setMmColor(e.currentTarget.value);
    setCurrentSlide(5);
  };

  const updateMMCountHandler = (e) => {
    e.preventDefault();
    console.log(`UPDATING ${e.currentTarget.value.toLowerCase()} COUNT`);
    updateMMCount(e.currentTarget.value.toLowerCase());
  };

  const updateMMCount = async (color) => {
    console.log(`COLOR SELECTED: ${color}`);
    const colorRef = db.collection("mmHandouts").doc(color.toLowerCase());
    colorRef.update({
      count: firebase.firestore.FieldValue.increment(1),
    });
  };

  const submitHandler = async (email) => {
    console.log("SUBMIT HANDLER FIRED.");
    updateMMCount(mmColor);
    const VISITS_PAYLOAD = {
      continent,
      disneyCharacter,
      emailAddress: email,
      previousAttendee,
      returningVisitor,
      occupation,
      mmColor,
      timestamp: Date.now(),
    };
    const EMAILS_PAYLOAD = {
      emailAddress: email,
      timestamp: Date.now(),
    };
    try {
      await db.collection("boothVisits2023").add(VISITS_PAYLOAD);
      console.log("DATA SUCCESSFULLY SUBMITTED");
    } catch (err) {
      console.log(`ERROR SUBMITTING BOOTH VISIT DATA:\n${err.message}`);
    }
    // db.collection("boothVisits")
    //   .add(VISITS_PAYLOAD)
    //   .then((doc) => console.log("DATA SUCCESSFULLY SUBMITTED"))
    //   .catch((err) =>
    //     console.log(`ERROR SENDING TO FIRESTORE:\n${err.message}`)
    //   );
    try {
      await db.collection("emails").add(EMAILS_PAYLOAD);
      console.log("DATA SUCCESSFULLY SUBMITTED");
    } catch (err) {
      console.log(`ERROR SENDING EMAIL TO DB:\n${err.message}`);
    }
    // send email to argonath for mailerlite campaign
    try {
      await fetch(
        `${process.env.REACT_APP_ARGONATH_ENDPOINT}/api/global/sendToMailerlite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailAddress: email }),
        }
      );
      console.log(`Email successfully submitted!`);
    } catch (err) {
      console.log(`ERROR SENDING EMAIL TO ARGONATH:\n${err.message}`);
    }
    setTimeout(() => {
      setCurrentSlide(7);
      clearValues();
    }, 2000);
  };

  const clearValues = () => {
    // setTravelMethod(undefined);
    setContinent(undefined);
    setDisneyCharacter(undefined);
    setPreviousAttendee(undefined);
    setOccupation(undefined);
    setMmColor(undefined);
  };

  const exitSurveyHandler = (e) => {
    e.preventDefault();
    setCurrentSlide(0);
    clearValues();
  };

  const loopBackHandler = () => {
    setCurrentSlide(0);
    clearValues();
  };

  return (
    <>
      <Routes>
        <Route
          exact
          path="/*"
          element={
            <>
              {currentSlide !== 0 && windowDimensions.width > 420 && (
                <ExitButton onClick={exitSurveyHandler}>
                  <CloseIcon style={{ color: "var(--phy-white)" }} />
                </ExitButton>
              )}

              <Container>
                <img className="logo" src={logo} alt="Phytest Wordmark Logo" />

                {currentSlide === 0 && (
                  <ContentSlide
                    heading="We'd love to hear from you"
                    subHeading="Can you help us contribute data to our dashboard by answering a few quick questions? It'll take 2 minutes, tops."
                    onClick={beginSurveyHandler}
                  />
                )}
                {currentSlide === 1 && (
                  <ContentSlide
                    heading="What continent did you travel from?"
                    options={CONTINENT_OPTIONS}
                    iconless={true}
                    onClick={continentHandler}
                  />
                )}
                {currentSlide === 2 && !previousAttendee && (
                  <ContentSlide
                    heading="Have you previously attended an AACC conference?"
                    options={PREVIOUS_ATTENDEE_OPTIONS}
                    onClick={previousAttendeeHandler}
                  />
                )}
                {currentSlide === 2 && previousAttendee && (
                  <ContentSlide
                    heading="Have you visited our booth before?"
                    options={PREVIOUS_ATTENDEE_OPTIONS}
                    onClick={returningVisitorHandler}
                  />
                )}
                {currentSlide === 3 && (
                  <ContentSlide
                    heading="What is your occupation?"
                    options={OCCUPATION_OPTIONS}
                    iconless={true}
                    onClick={occupationHandler}
                  />
                )}
                {currentSlide === 4 && (
                  <ContentSlide
                    heading="What color M&Ms did you select?"
                    options={MM_OPTIONS}
                    iconless={false}
                    onClick={mmColorHandler}
                  />
                )}
                {currentSlide === 5 && (
                  <ContentSlide
                    heading="Who is your favorite disney character?"
                    options={DISNEY_CHARACTER_OPTIONS}
                    iconless={true}
                    portraits={true}
                    onClick={disneyCharacterHandler}
                  />
                )}
                {currentSlide === 6 && (
                  <FormSubmission
                    heading="Thank you, your answers will be live!"
                    subHeading="Provide your email and see how your answers contribute to our live dashboard."
                    submitHandler={submitHandler}
                  />
                )}
                {currentSlide === 7 && (
                  <CompletionScreen
                    loopBack={loopBackHandler}
                    windowDimensions={windowDimensions}
                  />
                )}
                <SlideCounter currentSlide={currentSlide} />
              </Container>
            </>
          }
        />
        <Route
          exact
          path="/super-secret-page"
          element={
            <Container>
              <ContentSlide
                heading="INCREMENT M&M COLOR:"
                options={MM_OPTIONS}
                iconless={false}
                onClick={updateMMCountHandler}
              />
            </Container>
          }
        />
      </Routes>
    </>
  );
};

export default App;
