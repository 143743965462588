import styled from "styled-components";

const Input = styled.div`
  width: 423px;
  height: 48px;
  font-size: 1.25rem;
  padding: 2px;
  border-radius: 30px;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: flex-start;
  align-items: center;
  color: #03263c;

  & svg {
    font-size: 22px;
    padding-left: 16px;
  }

  & input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 24px;
    padding-left: 16px;
  }

  & input:focus {
    border: none;
    outline: none;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;

export default Input;
