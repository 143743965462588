import styled from "styled-components";

const CharacterOption = styled.button`
  width: 156px;
  height: 156px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: none;
  border: 3px solid var(--phy-white);
  border-radius: 100%;
  background: var(--phy-white);
  color: var(--color-phyBlueHue);
  transition: 0.5s;
  margin: 8px;
  & img {
    height: 70px;
  }
  & h3 {
    margin: none;
    font-size: 1.5rem;
    font-weight: 400;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
  }
  &:active {
    transform: scale(0.95, 0.95);
  }

  @media screen and (max-width: 800px) {
  }
  @media screen and (max-width: 400px) {
    width: 112px;
    height: 112px;
    & img {
      height: 50px;
    }
    & h3 {
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

export default CharacterOption;
