import styled from "styled-components";

const CounterContainer = styled.div`
  width: 256px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 90%;
  color: var(--phy-white);

  & h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
  }

  & .barBox {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  & .bar-top,
  & .bar-bottom {
    flex: 1;
  }
  & .bar-top {
    border-bottom: 1px solid var(--phy-white);
  }
  & .bar-bottom {
    border-top: 1px solid var(--phy-white);
  }
`;

export default CounterContainer;
