import styled from "styled-components";

const ExitButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid var(--phy-white);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  opacity: 0.5;
  position: fixed;
  top: 5%;
  left: 90%;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    left: 80%;
    top: 3%;
  }
`;

export default ExitButton;
