import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, var(--color-phyPurpleHue), var(--color-phyBlueHue));
  background-size: 150%;
  background-position: left;
  animation: bg-glow 8s ease-in-out infinite alternate;

  & #counter {
    position: fixed;
    top: 90%;
    width: 256px;
    display: flex;
    align-items: bottom;
    justify-content: space-around;
    color: var(--phy-white);
  }

  @keyframes bg-glow {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
`;

export default Container;
