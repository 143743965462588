import styled from "styled-components";

const Button = styled.button`
  height: 54px;
  padding: 2px;
  background: var(--color-phyGreenHue);
  color: var(--color-phyGray);
  border-radius: 30px;
  padding: 15px 30px;
  font-size: 1.25rem;
  border: none;
  transition: 0.5s;

  &:hover {
    background: var(--phy-purple);
    /* box-shadow: 0px 6px 18px var(--phy-green); */
    cursor: pointer;

  }

  &:active {
    background-color: var(--phy-purple);
    transform: scale(0.9, 0.9);
    box-shadow: none;
    transition: 0.05s;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    background-color: #373d4d;
  }
`;

export default Button;
