import { useEffect, useState } from "react";
import { PREVIOUS_ATTENDEE_OPTIONS } from "../dataOptions";
import Slide from "./styles/Slide.styled";

const CompletionScreen = ({ loopBack, windowDimensions }) => {
  const [countdown, setCountDown] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((previous) => {
        if (previous === 1) {
          clearInterval(interval);
          loopBack();
          return previous;
        } else {
          return (previous -= 1);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [loopBack]);

  return (
    <Slide>
      <h1>Thank you for participating!</h1>
      <h1>Your data has been added to the dashboard.</h1>
      <p>Resetting in {countdown}</p>
      {windowDimensions.width <= 420 && (
        <a
          href="https://dashboard.phytest.com"
          target="_blank"
          rel="noreferrer"
          className="outLink"
        >
          View Dashboard
        </a>
      )}
    </Slide>
  );
};

export default CompletionScreen;
