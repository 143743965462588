import CounterContainer from "./styles/CounterContainer.styled";

const SlideCounter = ({ currentSlide }) => {
  const headerContent = currentSlide === 7 ? "COMPLETED" : `${currentSlide + 1} of 7`;
  return (
    <CounterContainer>
      <div className="barBox">
        <div className="bar-top"></div>
        <div className="bar-bottom"></div>
      </div>
      <h1>{headerContent}</h1>
      <div className="barBox">
        <div className="bar-top"></div>
        <div className="bar-bottom"></div>
      </div>
    </CounterContainer>
  );
};

export default SlideCounter;
